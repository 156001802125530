import React, { useState } from 'react';
import { Box, TextField, Button, MenuItem } from '@mui/material';
import emailjs from '@emailjs/browser';

type FormSectionProps = {
  backgroundImage: string;
  topImage: string;
  middleImage: string;
};

const FormSection: React.FC<FormSectionProps> = ({ backgroundImage, topImage, middleImage }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    celular: '',
    email: '',
    tipoNegocio: '',
    comentarios: '', // Nuevo campo para comentarios
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Enviar datos a EmailJS
    emailjs
      .send(
        'service_cxpux78', // Reemplaza con tu Service ID
        'template_ksjljg7', // Reemplaza con tu Template ID
        {
          nombre: formData.nombre,
          celular: formData.celular,
          email: formData.email,
          tipoNegocio: formData.tipoNegocio,
          comentarios: formData.comentarios, // Enviar comentarios también
        },
        '8UMvamJvQnij9k0_O' // Reemplaza con tu Public Key de EmailJS
      )
      .then(
        (result) => {
          window.alert('¡Tu cita se ha agendado con éxito! Pronto nos pondremos en contacto contigo.');
          setFormData({
            nombre: '',
            celular: '',
            email: '',
            tipoNegocio: '',
            comentarios: '',
          }); // Reiniciar formulario
        },
        (error) => {
          console.error('Error al enviar el correo:', error);
          window.alert('Hubo un error al agendar tu cita. Por favor, inténtalo de nuevo.');
        }
      );
  };

  return (
    <Box
      className="form-section"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        display: 'flex',
        height: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      {/* Sección Izquierda con las Imágenes */}
      <Box
        className="form-section-left"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40%',
          maxWidth: '300px',
          marginRight: '5%',
        }}
      >
        <Box sx={{ width: '60%', marginBottom: '20px' }}>
          <img src={topImage} alt="Top Image" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Box>
        <Box sx={{ width: '100%', maxWidth: '280px' }}>
          <img src={middleImage} alt="Middle Image" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Box>
      </Box>

      {/* Sección Derecha con el Formulario */}
      <Box
        className="form-section-right"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
          maxWidth: '450px',
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          padding: '30px',
        }}
      >
        <h2 style={{ marginBottom: '20px', color: '#5A3E8E' }}>Agenda tu cita</h2>
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <TextField
            name="nombre"
            label="Nombre"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={formData.nombre}
            onChange={handleChange}
          />
          <TextField
            name="celular"
            label="Celular"
            variant="outlined"
            fullWidth
            margin="normal"
            type="tel"
            required
            value={formData.celular}
            onChange={handleChange}
          />
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            name="tipoNegocio"
            label="Tipo de Negocio"
            variant="outlined"
            select
            fullWidth
            margin="normal"
            required
            value={formData.tipoNegocio}
            onChange={handleChange}
          >
            <MenuItem value="Salón de belleza">Salón de belleza</MenuItem>
            <MenuItem value="Barbería">Barbería</MenuItem>
            <MenuItem value="Otros">Otros</MenuItem>
          </TextField>
          {/* Campo para comentarios opcional */}
          <TextField
            name="comentarios"
            label="Comentarios (opcional)"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.comentarios}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginTop: '20px',
              backgroundColor: '#5A3E8E',
              color: '#FFF',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#2E1A47',
              },
            }}
          >
            Agendar Cita
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default FormSection;
